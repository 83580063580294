.example1-dropdown-menu {
  display: flex;
  flex-direction: column;
  top: 40px;
  right: 0;
  padding: 8px 0px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #c3c5c7;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    transition: 0.2s ease-in;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: 0.2s ease-in;
  }
}

.example1-dropdown-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 26px;
  font-size: 12px;
  color: #333537;

  &:hover {
    background-color: #f3f5f7;
  }

  &__spacer {
    width: 6px;
  }

  &__text {
    padding: 0px 2px;
  }
}
